'use strict';

const _merge = require('lodash/merge');
const Cookie = require('cookie');
const Qs = require('qs');
const Xhr = require('xhr');
const AuthHelper = require('./auth');

const saveBlob = function (blob, fileName) {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
};

const fileFetch = function (options, callback) {
    const cookies = Cookie.parse(document.cookie);
    const config = {
        url: options.url,
        method: options.method,
        headers: {},
        responseType: 'blob'
    };

    config.headers = {
        'Content-Type': 'application/json'
    };

    if (cookies.crumb && options.allowCrumb !== false) {
        config.headers['X-CSRF-Token'] = cookies.crumb;
    }

    if (options.query) {
        config.url += '?' + Qs.stringify(options.query, { arrayFormat: 'repeat', encode: true });
    }

    if (options.data) {
        config.body =
            options.type !== 'multipart/form-data' ? JSON.stringify(options.data) : options.data;
    }

    if (options.headers) {
        _merge(config.headers, options.headers);
    }

    Xhr(config, (err, response, body) => {
        if (err) {
            return callback(err);
        }

        if (response.statusCode >= 200 && response.statusCode < 300) {
            if (response.headers.hasOwnProperty('x-auth-required')) {
                let returnUrl = window.location.pathname;

                if (window.location.search.length > 0) {
                    returnUrl += window.location.search;
                }

                returnUrl = encodeURIComponent(returnUrl);

                window.location.href = `/login?returnUrl=${returnUrl}`;
            } else {
                const fileName =
                    response.headers['content-disposition'].match(/\sfilename=([^"]+)(\s|$)/)[1];
                saveBlob(body, fileName);
                callback(null, body);
            }
        } else if (response.statusCode === 401) {
            AuthHelper.logout();
        } else {
            const httpErr = new Error(response.rawRequest.statusText);
            if (body) {
                body.text().then((bodyText) => {
                    try {
                        const parsedBody = JSON.parse(bodyText);
                        callback(httpErr, parsedBody);
                    } catch (err) {
                        console.log(err);
                        callback(httpErr);
                    }
                });
            } else {
                callback(httpErr);
            }
        }
    });
};

window.fileFetch = fileFetch;

module.exports = fileFetch;
