'use strict';

const _set = require('lodash/set');

const parseReason = function (message) {
    const regexBecause = /because \[(.*?)(\]|$)/;
    let parsedMessage = message;

    if (regexBecause.test(message)) {
        parsedMessage = regexBecause.exec(message)[1];
    }

    if (regexBecause.test(parsedMessage)) {
        parsedMessage = parseReason(parsedMessage);
    }

    return parsedMessage;
};

const parseValidation = function (response) {
    const validation = response && response.validation;
    const message = response && response.message;
    const error = response && response.error;
    const data = response && response.data;
    const statusCode = response && response.statusCode;
    const result = {
        error: undefined,
        errorMessage: undefined,
        hasError: {},
        help: {}
    };

    if (validation && validation.keys && validation.keys.length > 0) {
        const forField = validation.keys[validation.keys.length - 1];
        const reason = parseReason(message);

        _set(result.hasError, forField, true);
        _set(result.help, forField, reason);

    } else if (error) {
        result.error = error;
        result.errorMessage = message;
        result.data = data;
        result.statusCode = statusCode;
    }

    return result;
};

module.exports = parseValidation;
