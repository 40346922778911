'use strict';

const Constants = require('../constants');
const ObjectAssign = require('object-assign');
const Redux = require('redux');

const initialState = {
    success: false,
    show: false,
    commit: '',
    date: '',
    version: ''
};
const reducer = function (state = initialState, action) {
    if (action.type === Constants.GET_BUILD_INFO) {
        return ObjectAssign({}, state);
    }

    if (action.type === Constants.GET_BUILD_INFO_RESPONSE) {
        return ObjectAssign({}, state, {
            success: true,
            show: action.response.show,
            commit: action.response.commit,
            date: action.response.date,
            version: action.response.version
        });
    }

    return state;
};

module.exports = Redux.createStore(reducer);
