'use strict';

const React = require('react');
const PropTypes = require('prop-types');
const ReactRouter = require('react-router-dom');

const Loading = require('../../../components/loading.jsx');
const Store = require('./store');
const BuildInfo = require('../build-info/index.jsx');
const Actions = require('../actions');

const Link = ReactRouter.Link;

const propTypes = {
    match: PropTypes.object
};

class ConfirmEmailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = Store.getState();
    }

    componentDidMount() {
        this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));

        const { email, key } = this.props.match.params;
        const data = {
            email,
            key
        };
        Actions.confirmEmail(data);
    }

    componentWillUnmount() {
        this.unsubscribeStore();
    }

    onStoreChange() {
        this.setState(Store.getState());
    }

    render() {
        const { loading, success, errorMessage } = this.state;

        let title = 'Congratulation!';
        let message = 'The email was successfully confirmed';
        let messageContent;

        if (loading) {
            messageContent = <Loading inner />;
        } else {
            if (!success) {
                title = 'An error occurred';
                message = errorMessage;
            }

            messageContent = (
                <>
                    <header className="modal-header-wrapper">
                        <div className="title">
                            <h1 className={!success ? 'error' : ''}>{title}</h1>
                        </div>
                    </header>
                    <main className="modal-content-wrapper">
                        <div className="details-wrapper">
                            <div className="grid-wrapper">
                                <div className={success ? 'full-width' : 'full-width error'}>
                                    {message}
                                </div>
                                {success ? (
                                    <div className="full-width flex indent-p-top-3x">
                                        <Link to="/login">Back to Login page</Link>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </main>
                </>
            );
        }

        return (
            <div>
                <div className="modal-outer-pop-up-view">
                    <div className="modal-wrapper">
                        <div className="modal-curtain" />
                        <div className="modal">{messageContent}</div>
                    </div>
                </div>
                <BuildInfo />
            </div>
        );
    }
}

ConfirmEmailPage.propTypes = propTypes;

module.exports = ConfirmEmailPage;
