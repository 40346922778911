'use strict';

const PropTypes = require('prop-types');
const React = require('react');

const propTypes = {
    children: PropTypes.node,
    inline: PropTypes.bool,
    message: PropTypes.string,
    animation: PropTypes.string,
    type: PropTypes.oneOf(['success', 'info', 'warning', 'danger']),
    buttons: PropTypes.array,
    showIcon: PropTypes.bool,
    iconClasses: PropTypes.string,
    contentClasses: PropTypes.string,
    onClose: PropTypes.func
};

const defaultProps = {
    inline: false,
    showIcon: true,
    iconClasses: '',
    contentClasses: ''
};

class Alert extends React.Component {
    render() {
        let close = null;
        let actions = null;
        let icon = null;
        let content = null;

        if (this.props.onClose) {
            close = (
                <button
                    type="button"
                    className="alert-action close-action tertiary only-icon-always icon-cross-red"
                    onClick={this.props.onClose}
                ></button>
            );
        }

        let alertWrapperStyles = `alert-wrapper ${
            this.props.inline ? 'inline-view' : 'modal-view'
        } ${this.props.type}`;
        let alertStyles = 'content';

        if (this.props.onClose) {
            alertStyles += this.props.animation ? ' alert-fade-in' : '';
        } else {
            alertWrapperStyles += ' alert-hide';
            alertStyles += this.props.animation ? ' alert-fade-in-out' : '';
        }

        if (this.props.buttons) {
            let buttonIndex = 0;
            const buttons = this.props.buttons.map((button) => {
                const buttonStyles = 'alert-action ' + button.styles;
                buttonIndex++;

                return (
                    <button
                        key={buttonIndex}
                        type="button"
                        className={buttonStyles}
                        onClick={button.onClick}
                    >
                        {button.title}
                    </button>
                );
            });

            actions = <p className="alert-actions">{buttons}</p>;
        }

        if (this.props.showIcon) {
            icon = (
                <img
                    className={`alert-icon ${this.props.iconClasses}`}
                    src={`/public/media/icon_alert_${this.props.type}.svg`}
                />
            );
        }

        if (this.props.children) {
            content = (
                <div className={`alert-content ${this.props.contentClasses}`}>
                    {this.props.children}
                </div>
            );
        } else {
            content = <span>{this.props.message}</span>;
        }

        return (
            <div className={alertWrapperStyles}>
                <div className={alertStyles}>
                    {icon}
                    {content}
                    {close}
                    {actions}
                </div>
            </div>
        );
    }
}

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;

module.exports = Alert;
