'use strict';

const React = require('react');
const PropTypes = require('prop-types');
const ClassNames = require('classnames');
const ControlGroup = require('./control-group.jsx');

const propTypes = {
    autoCapitalize: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    hasError: PropTypes.bool,
    help: PropTypes.string,
    inputClasses: PropTypes.object,
    className: PropTypes.string,
    groupClasses: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onKeyUp: PropTypes.func,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    enableShowPassword: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hideHelp: PropTypes.bool,
    hideLabel: PropTypes.bool
};
const defaultProps = {
    className: '',
    type: 'text',
    autoCapitalize: 'off',
    enableShowPassword: false
};

class TextControl extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            passwordIsVisible: false
        };
    }

    focus() {
        return this.input.focus();
    }

    value() {
        return this.input.value;
    }

    handleShowPasswordClick = () => {
        const { passwordIsVisible } = this.state;
        this.setState({
            passwordIsVisible: !passwordIsVisible
        });
    };

    renderInput() {
        const { enableShowPassword } = this.props;
        const { passwordIsVisible } = this.state;

        return (
            <input
                ref={(c) => (this.input = c)}
                type={passwordIsVisible ? 'text' : this.props.type}
                autoCapitalize={this.props.autoCapitalize}
                className={`${this.props.className} ${enableShowPassword ? 'with-icon' : ''}`}
                name={this.props.name}
                placeholder={this.props.placeholder}
                value={this.props.value}
                disabled={this.props.disabled ? 'disabled' : undefined}
                required={this.props.required ? 'required' : undefined}
                onChange={this.props.onChange}
                onKeyUp={this.props.onKeyUp}
            />
        );
    }

    render() {
        const { type, enableShowPassword } = this.props;
        const { passwordIsVisible } = this.state;

        let showPasswordIcon = null;
        let input = null;
        if (type === 'password' && enableShowPassword) {
            const iconClasses = {
                'input-icon': true,
                eye: !passwordIsVisible,
                'eye-off': passwordIsVisible
            };
            showPasswordIcon = (
                <i className={ClassNames(iconClasses)} onClick={this.handleShowPasswordClick} />
            );

            input = (
                <div className="input-wrapper">
                    {this.renderInput()}
                    {showPasswordIcon}
                </div>
            );
        } else {
            input = this.renderInput();
        }

        return (
            <ControlGroup
                hasError={this.props.hasError}
                label={this.props.label}
                help={this.props.help}
                hideHelp={this.props.hideHelp}
                hideLabel={this.props.hideLabel}
                groupClasses={this.props.groupClasses}
            >
                {input}
            </ControlGroup>
        );
    }
}

TextControl.propTypes = propTypes;
TextControl.defaultProps = defaultProps;

module.exports = TextControl;
