'use strict';

module.exports = {
    FORGOT: 'FORGOT',
    FORGOT_RESPONSE: 'FORGOT_RESPONSE',
    LOGIN: 'LOGIN',
    LOGIN_RESPONSE: 'LOGIN_RESPONSE',
    LOGOUT: 'LOGOUT',
    LOGOUT_RESPONSE: 'LOGOUT_RESPONSE',
    RESET: 'RESET',
    RESET_RESPONSE: 'RESET_RESPONSE',
    CONFIRM_EMAIL_RESPONSE: 'CONFIRM_EMAIL_RESPONSE',
    SIGN_UP: 'SIGN_UP',
    SIGN_UP_RESPONSE: 'SIGN_UP_RESPONSE',
    HIDE_LOGIN_ERROR: 'HIDE_LOGIN_ERROR',
    HIDE_LOGOUT_ERROR: 'HIDE_LOGOUT_ERROR',
    HIDE_FORGOT_ERROR: 'HIDE_FORGOT_ERROR',
    HIDE_SIGN_UP_ERROR: 'HIDE_SIGN_UP_ERROR',
    GET_BUILD_INFO: 'GET_BUILD_INFO',
    GET_BUILD_INFO_RESPONSE: 'GET_BUILD_INFO_RESPONSE'
};
