'use strict';

const React = require('react');
const ReactRouter = require('react-router-dom');

const Link = ReactRouter.Link;

class NotFoundPage extends React.Component {
    render() {
        return (
            <div className="not-found">
                <div className="not-found-content">
                    <div className="icon" />
                    <div className="title">
                        <h1>Oops, something went wrong!</h1>
                    </div>
                    <div className="content">
                        <p>The page you were trying to view does not exist.</p>
                        <Link to="/login">Back to login</Link>
                    </div>
                </div>
            </div>
        );
    }
}

module.exports = NotFoundPage;
