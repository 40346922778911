'use strict';

const ApiActions = require('../../actions/api');
const Constants = require('./constants');
const ForgotStore = require('./forgot/store');
const LoginStore = require('./home/store');
const LogoutStore = require('./logout/store');
const SignUpStore = require('./registration/store');
const ResetStore = require('./reset/store');
const ConfirmEmailStore = require('./confirm-email/store');
const Qs = require('qs');
const Storage = require('../../helpers/storage');
const AuthHelper = require('../../helpers/auth');

class Actions {
    static forgot(data) {
        ApiActions.post(
            '/api/login/forgot',
            data,
            ForgotStore,
            Constants.FORGOT,
            Constants.FORGOT_RESPONSE
        );
    }

    static login(data) {
        ApiActions.post(
            '/api/login',
            data,
            LoginStore,
            Constants.LOGIN,
            Constants.LOGIN_RESPONSE,
            (err, response) => {
                if (!err) {
                    response.user.permissions = response.userPermissions;
                    Storage.set('user', response.user);
                    Storage.set('logoUrl', response.setting.logoUrl);
                    Storage.set('navbar', response.setting.navbar);
                    Storage.set('rootComponent', response.rootComponent);

                    const query = Qs.parse(window.location.search.substring(1));

                    if (query.returnUrl) {
                        window.location.href = query.returnUrl;
                    } else {
                        window.location.href = '/admin';
                    }
                }
            }
        );
    }

    static signUp(data) {
        ApiActions.post(
            '/api/sign-up',
            data,
            SignUpStore,
            Constants.SIGN_UP,
            Constants.SIGN_UP_RESPONSE,
            (err, response) => {
                if (!err) {
                    Storage.set('user', response.user);

                    const query = Qs.parse(window.location.search.substring(1));

                    if (query.returnUrl) {
                        window.location.href = query.returnUrl;
                    } else {
                        window.location.href = '/notFound';
                    }
                }
            }
        );
    }

    static logout() {
        ApiActions.delete(
            '/api/logout',
            undefined,
            LogoutStore,
            Constants.LOGOUT,
            Constants.LOGOUT_RESPONSE,
            (err) => {
                if (!err) {
                    AuthHelper.logout();
                    window.scrollTo(0, 0);
                }
            }
        );
    }

    static reset(data) {
        ApiActions.post(
            '/api/login/reset',
            data,
            ResetStore,
            Constants.RESET,
            Constants.RESET_RESPONSE,
            (err) => {
                if (!err) {
                    const query = Qs.parse(window.location.search.substring(1));

                    if (query.returnUrl) {
                        window.location.href = query.returnUrl;
                    }
                }
            }
        );
    }

    static externalReset(sourceId, data) {
        ApiActions.post(
            `/api/sources/${sourceId}/login/reset`,
            data,
            ResetStore,
            Constants.RESET,
            Constants.RESET_RESPONSE
        );
    }

    static confirmEmail(data) {
        ApiActions.post(
            '/api/login/confirm-email',
            data,
            ConfirmEmailStore,
            null,
            Constants.CONFIRM_EMAIL_RESPONSE
        );
    }

    static hideLoginError() {
        LoginStore.dispatch({
            type: Constants.HIDE_LOGIN_ERROR
        });
    }

    static hideSignUpError() {
        SignUpStore.dispatch({
            type: Constants.HIDE_SIGN_UP_ERROR
        });
    }

    static hideLogoutError() {
        LogoutStore.dispatch({
            type: Constants.HIDE_LOGOUT_ERROR
        });
    }

    static hideForgotError() {
        ForgotStore.dispatch({
            type: Constants.HIDE_FORGOT_ERROR
        });
    }

    static getBuildInfo(Store) {
        ApiActions.get(
            '/api/build-info',
            null,
            Store,
            Constants.GET_BUILD_INFO,
            Constants.GET_BUILD_INFO_RESPONSE
        );
    }
}

module.exports = Actions;
