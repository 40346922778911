'use strict';

const Actions = require('../actions');
const React = require('react');
const ReactRouter = require('react-router-dom');
const PropTypes = require('prop-types');
const Store = require('./store');
const Alert = require('../../../components/alert.jsx');

const Link = ReactRouter.Link;
const propTypes = {
    history: PropTypes.object
};

class LogoutPage extends React.Component {
    constructor(props) {
        super(props);

        this.input = {};
        this.state = Store.getState();
    }

    componentDidMount() {
        this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));

        Actions.logout();
    }

    componentWillUnmount() {
        this.unsubscribeStore();
    }

    onStoreChange() {
        this.setState(Store.getState());
    }

    render() {
        const alerts = [];

        if (this.state.error) {
            alerts.push(
                <Alert key="success" type="success" animation="true" message="Logout successful" />
            );

            // setTimeout( () => {
            //
            //     this.setState({
            //         error: false
            //     });
            // }, 3000);
        } else if (this.state.error) {
            alerts.push(
                <Alert
                    key="danger"
                    type="danger"
                    animation="true"
                    message={this.state.error}
                    onClose={Actions.hideLogoutError}
                />
            );
        }

        return (
            <div>
                <div className="modal-outer-pop-up-view">
                    <div className="modal-wrapper">
                        <div className="modal">
                            <Link to="/login">Back to login</Link>
                            <main className="modal-content-wrapper">{alerts}</main>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

LogoutPage.propTypes = propTypes;

module.exports = LogoutPage;
