'use strict';

const Actions = require('../actions');
const Alert = require('../../../components/alert.jsx');
const React = require('react');
const ReactRouter = require('react-router-dom');
const Store = require('./store');
const TextControl = require('../../../components/form/text-control.jsx');
const PropTypes = require('prop-types');
const BuildInfo = require('../build-info/index.jsx');

const Link = ReactRouter.Link;
const propTypes = {
    location: PropTypes.object
};

class LoginHome extends React.Component {
    constructor(props) {
        super(props);

        this.input = {};
        this.state = Store.getState();
    }

    componentDidMount() {
        this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));

        if (this.input.username) {
            this.input.username.focus();
        }
    }

    componentWillUnmount() {
        this.unsubscribeStore();
    }

    onStoreChange() {
        this.setState(Store.getState());
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        Actions.login({
            username: this.input.username.value(),
            password: this.input.password.value()
        });
    }

    render() {
        const alerts = [];

        if (this.state.error) {
            alerts.push(
                <Alert
                    key="danger"
                    type="danger"
                    animation="true"
                    message={this.state.error}
                    onClose={Actions.hideLoginError}
                />
            );
        }

        const formElements = (
            <div className="grid-wrapper">
                <div className="full-width">
                    <TextControl
                        ref={(c) => (this.input.username = c)}
                        name="username"
                        hasError={this.state.hasError.username}
                        help={this.state.help.username}
                        disabled={this.state.loading}
                        placeholder="Email or username"
                        hideLabel={true}
                    />
                </div>
                <div className="full-width">
                    <TextControl
                        ref={(c) => (this.input.password = c)}
                        name="password"
                        type="password"
                        hasError={this.state.hasError.password}
                        help={this.state.help.password}
                        disabled={this.state.loading}
                        placeholder="Password"
                        components
                        hideLabel={true}
                    />
                </div>
                <div className="half-width modal-sub-actions flex flex-direction-column flex-align-top">
                    <Link className="modal-sub-action" to="/login/forgot">
                        Forgot password?
                    </Link>
                </div>
                <div className="half-width text-align-right">
                    <button type="submit" className="secondary" disabled={this.state.loading}>
                        Login
                    </button>
                </div>
            </div>
        );

        return (
            <div>
                <div className="modal-outer-pop-up-view">
                    <div className="modal-wrapper">
                        <div className="modal-curtain" />
                        <div className="modal">
                            <header className="modal-header-wrapper">
                                <div className="title">
                                    <h1>Login</h1>
                                </div>
                            </header>

                            <main className="modal-content-wrapper">
                                <form
                                    onSubmit={this.handleSubmit.bind(this)}
                                    className="details-wrapper"
                                >
                                    {alerts}
                                    {formElements}
                                </form>
                            </main>
                        </div>
                    </div>
                </div>
                <BuildInfo />
            </div>
        );
    }
}

LoginHome.propTypes = propTypes;

module.exports = LoginHome;
