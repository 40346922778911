'use strict';

const Actions = require('../actions');
const React = require('react');
const Store = require('./store');

class BuildInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = Store.getState();
    }

    componentDidMount() {
        this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));

        Actions.getBuildInfo(Store);
    }

    componentWillUnmount() {
        this.unsubscribeStore();
    }

    onStoreChange() {
        this.setState(Store.getState());
    }

    render() {
        let buildInfo = null;
        const { show, version, date, commit } = this.state;

        if (show) {
            buildInfo = (
                <p className="build-info">
                    <span>Version: {version}</span>
                    <span>
                        Build date: {date}, Build commit: {commit}
                    </span>
                </p>
            );
        }

        return buildInfo;
    }
}

module.exports = BuildInfo;
