'use strict';

const Storage = require('./storage');
const Permissions = require('../enums/permissions');

class AuthHelper {
    static hasAccessTo(permission, datasetId) {
        const user = Storage.get('user');

        if (user && user.permissions) {
            const permissionsKeys = Object.keys(user.permissions);

            if (permissionsKeys.includes(Permissions.FULL_ACCESS)) {
                return true;
            }

            if (datasetId) {
                for (const permissionsKey of permissionsKeys) {
                    const permissionDef = user.permissions[permissionsKey];
                    if (permissionDef.datasetId && permissionDef.datasetId === datasetId) {
                        return true;
                    }
                }
            } else if (Array.isArray(permission)) {
                for (const p of permission) {
                    if (permissionsKeys.includes(p)) {
                        return true;
                    }
                }
            } else {
                return permissionsKeys.includes(permission);
            }
        }

        return false;
    }

    static getSourceDataPermissions(sourceId) {
        const permissions = {
            read: true,
            create: true,
            delete: true,
            update: true,
            archive: false
        };

        const user = Storage.get('user');

        if (user && user.permissions) {
            const permissionsKeys = Object.keys(user.permissions);

            if (!permissionsKeys.includes(Permissions.FULL_ACCESS)) {
                if (permissionsKeys.includes(Permissions.DATA_ARCHIVE)) {
                    permissions.archive = true;
                }

                const dataSourcePermissionsKey = permissionsKeys.find(
                    (pKey) => user.permissions[pKey].datasetId === sourceId
                );

                if (dataSourcePermissionsKey) {
                    const dataSourcePermissions = user.permissions[dataSourcePermissionsKey];

                    permissions.read = dataSourcePermissions.read;
                    permissions.create = dataSourcePermissions.create;
                    permissions.delete = dataSourcePermissions.delete;
                    permissions.update = dataSourcePermissions.update;

                    if (dataSourcePermissions.hasOwnProperty('archive')) {
                        permissions.archive = dataSourcePermissions.archive;
                    }
                }
            }
        }

        return permissions;
    }

    static getUser() {
        return Storage.get('user');
    }

    static logout() {
        Storage.remove('user');

        if (window.location.pathname.includes('logout')) {
            window.location.href = '/login';
        } else {
            let returnUrl = window.location.pathname;

            if (window.location.search.length > 0) {
                returnUrl += window.location.search;
            }

            returnUrl = encodeURIComponent(returnUrl);

            window.location.href = `/login?returnUrl=${returnUrl}`;
        }
    }
}

module.exports = AuthHelper;
