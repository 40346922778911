'use strict';

const Engine = require('store/src/store-engine');

// Store.js will pick the best available storage, and automatically falls back to the first available storage that works
const defaultStorages = [
    require('store/storages/localStorage'),
    require('store/storages/cookieStorage')
];

const sessionStorages = [
    require('store/storages/sessionStorage'),
    require('store/storages/memoryStorage')
];

const plugins = [];

const defaultStore = Engine.createStore(defaultStorages, plugins);
const sessionStore = Engine.createStore(sessionStorages, plugins);

module.exports = {
    get: defaultStore.get.bind(defaultStore),
    set: defaultStore.set.bind(defaultStore),
    remove: defaultStore.remove.bind(defaultStore),
    clearAll: defaultStore.clearAll.bind(defaultStore),
    each: defaultStore.each.bind(defaultStore),
    session: sessionStore
};
