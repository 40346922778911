'use strict';

const Actions = require('../actions');
const React = require('react');
const ReactRouter = require('react-router-dom');
const Store = require('./store');
const TextControl = require('../../../components/form/text-control.jsx');
const BuildInfo = require('../build-info/index.jsx');

const PropTypes = require('prop-types');

const Link = ReactRouter.Link;
const propTypes = {
    match: PropTypes.object
};

class ResetPage extends React.Component {
    constructor(props) {
        super(props);

        this.input = {};
        this.state = Store.getState();
    }

    componentDidMount() {
        this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));

        if (this.input.password) {
            this.input.password.focus();
        }
    }

    componentWillUnmount() {
        this.unsubscribeStore();
    }

    onStoreChange() {
        this.setState(Store.getState());
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const payload = {
            email: this.props.match.params.email,
            key: this.props.match.params.key
        };

        const { password, passwordRepeat } = this.input;
        if (password.value()) {
            payload.password = password.value();
        }

        if (passwordRepeat.value()) {
            payload.passwordRepeat = passwordRepeat.value();
        }

        Actions.reset(payload);
    }

    render() {
        const alerts = [];

        if (this.state.success) {
            alerts.push(
                <div key="success">
                    <div className="alert alert-success">
                        Your password has been reset. Please login to confirm.
                    </div>
                    <Link to="/login" className="btn btn-link">
                        Back to login
                    </Link>
                </div>
            );
        }

        if (this.state.error) {
            alerts.push(
                <div key="danger" className="alert alert-danger">
                    {this.state.error}
                </div>
            );
        }

        let formElements;

        if (!this.state.success) {
            formElements = (
                <fieldset>
                    <div className="grid-wrapper">
                        <div className="full-width">
                            <TextControl
                                name="_email"
                                label="Email"
                                hasError={this.state.hasError.email}
                                value={this.props.match.params.email}
                                help={this.state.help.email}
                                disabled={true}
                            />
                        </div>
                        <div className="full-width">
                            <TextControl
                                ref={(c) => (this.input.password = c)}
                                name="password"
                                label="New password"
                                type="password"
                                enableShowPassword
                                hasError={this.state.hasError.password}
                                help={this.state.help.password}
                                disabled={this.state.loading}
                            />
                        </div>
                        <div className="full-width">
                            <TextControl
                                ref={(c) => (this.input.passwordRepeat = c)}
                                name="passwordRepeat"
                                label="Repeat password"
                                type="password"
                                enableShowPassword
                                hasError={this.state.hasError.passwordRepeat}
                                help={this.state.help.passwordRepeat}
                                disabled={this.state.loading}
                            />
                        </div>
                        <div className="half-width">
                            <Link to="/login">Back to login</Link>
                        </div>
                        <div className="half-width text-align-right">
                            <button
                                type="submit"
                                className="secondary"
                                disabled={this.state.loading}
                            >
                                Set password
                            </button>
                        </div>
                    </div>
                </fieldset>
            );
        }

        return (
            <div>
                <div className="modal-outer-pop-up-view">
                    <div className="modal-wrapper">
                        <div className="modal-curtain" />
                        <div className="modal">
                            <header className="modal-header-wrapper">
                                <div className="title">
                                    <h1>Reset your password</h1>
                                </div>
                            </header>
                            <main className="modal-content-wrapper">
                                <form
                                    onSubmit={this.handleSubmit.bind(this)}
                                    className="details-wrapper"
                                >
                                    {alerts}
                                    {formElements}
                                </form>
                            </main>
                        </div>
                    </div>
                </div>
                <BuildInfo />
            </div>
        );
    }
}

ResetPage.propTypes = propTypes;

module.exports = ResetPage;
