'use strict';

const React = require('react');
const PropTypes = require('prop-types');
const ClassNames = require('classnames');

const propTypes = {
    message: PropTypes.string,
    inner: PropTypes.bool
};

class Loading extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const loaderClasses = {
            'loading-wrapper': true,
            inner: this.props.inner
        };

        return (
            <div className={ClassNames(loaderClasses)}>
                <div className="preloader" />
                {this.props.message && <p className="message">{this.props.message}</p>}
            </div>
        );
    }
}

Loading.propTypes = propTypes;

module.exports = Loading;
