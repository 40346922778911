'use strict';

const JsonFetch = require('../helpers/json-fetch');
const FileFetch = require('../helpers/file-fetch');

class Actions {
    static get(url, query, store, typeReq, typeRes, callback, passVars) {
        const request = { method: 'GET', url, query };
        return this.makeRequest(request, store, typeReq, typeRes, callback, passVars);
    }

    static getContent(url, query, store, typeReq, typeRes, callback) {
        const request = { method: 'GET', type: 'content', url, query };
        return this.makeRequest(request, store, typeReq, typeRes, callback);
    }

    static put(url, data, store, typeReq, typeRes, callback, passVars) {
        const request = { method: 'PUT', url, data };
        return this.makeRequest(request, store, typeReq, typeRes, callback, passVars);
    }

    static post(url, data, store, typeReq, typeRes, callback, passVars) {
        const request = { method: 'POST', url, data };
        return this.makeRequest(request, store, typeReq, typeRes, callback, passVars);
    }

    static formData(url, data, store, typeReq, typeRes, callback) {
        const request = {
            method: 'PUT',
            type: 'multipart/form-data',
            url,
            data
        };
        return this.makeRequest(request, store, typeReq, typeRes, callback);
    }

    static formDataPost(url, data, store, typeReq, typeRes, callback, onProgress) {
        const request = {
            method: 'POST',
            type: 'multipart/form-data',
            url,
            data,
            onProgress
        };
        this.makeRequest(request, store, typeReq, typeRes, callback);
    }

    static delete(url, query, store, typeReq, typeRes, callback) {
        const request = { method: 'DELETE', url, query };
        return this.makeRequest(request, store, typeReq, typeRes, callback);
    }

    static downloadFile(url, query, accept, store, typeReq, typeRes, callback) {
        const request = { method: 'GET', url, query, headers: { accept } };
        return this.downloadRequest(request, store, typeReq, typeRes, callback);
    }

    static downloadFilePost(url, data, accept, store, typeReq, typeRes, callback) {
        const request = { method: 'POST', url, data, headers: { accept } };
        return this.downloadRequest(request, store, typeReq, typeRes, callback);
    }

    static makeRequest(request, store, typeReq, typeRes, callback, passVars) {
        if (typeReq) {
            store.dispatch({
                type: typeReq,
                request,
                passVars
            });
        }

        return new Promise((resolve, reject) => {
            JsonFetch(request, (err, response) => {
                if (response && passVars) {
                    response.passVars = passVars;
                }

                if (typeRes) {
                    store.dispatch({
                        type: typeRes,
                        err,
                        response,
                        passVars
                    });
                }

                if (callback) {
                    callback(err, response);
                }

                if (err) {
                    return reject({
                        err,
                        response
                    });
                }

                return resolve(response);
            });
        }).catch((e) => {
            console.log(e);
        });
    }

    static downloadRequest(request, store, typeReq, typeRes, callback) {
        if (typeReq) {
            store.dispatch({
                type: typeReq,
                request
            });
        }

        return new Promise((resolve, reject) => {
            FileFetch(request, (err, response) => {
                if (callback) {
                    callback(err, response);
                }

                if (typeRes) {
                    store.dispatch({
                        type: typeRes,
                        err,
                        response
                    });
                }

                if (err) {
                    return reject({
                        err
                    });
                }

                return resolve(response);
            });
        }).catch((e) => {
            console.log(e);
        });
    }
}

module.exports = Actions;
