'use strict';

// module.exports = require('../../server/models/permissions').globals;

module.exports = {
    USERS: 'users',
    MEETINGS: 'meetings',
    ASSETS: 'assets',
    TEMPLATES: 'templates',
    SOCIAL_MEDIA_BOTS: 'social-media-bots',
    ANALYTICS: 'analytics',
    SETTINGS: 'settings',
    INSIGHTS: 'insights',
    GROUPS: 'groups',
    ROLES: 'roles',
    PERMISSIONS: 'permissions',
    COLLECTIONS: 'collections',
    VOTING: 'voting',
    EVENTING: 'eventing',
    VIEWER: 'viewer',
    APPS: 'apps',
    DATA_PROMOTION: 'data-promotion',
    DATA_VIEW: 'data-view',
    DATA_ARCHIVE: 'data-archive',
    DXP_ACCESS: 'dxp-access',
    SEO_BULK_EDITOR: 'seo-bulk-editor',
    // time tracking permissions
    TIME_TRACKING_EMPLOYEE_LOCATIONS: 'time-tracking-employee-locations',
    // not visible permissions for internal use
    FULL_ACCESS: 'full-access',
    ANY: 'any-permission'
};
