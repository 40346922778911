'use strict';

const _merge = require('lodash/merge');
const Cookie = require('cookie');
const Qs = require('qs');
const Xhr = require('xhr');
const AuthHelper = require('./auth');

const jsonFetch = function (options, callback) {
    const cookies = Cookie.parse(document.cookie);
    const config = {
        url: options.url,
        method: options.method,
        headers: {}
    };

    if (
        options.type !== 'multipart/form-data' &&
        options.type !== 'content' &&
        options.allowDefaultHeaders !== false
    ) {
        config.headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        };
    }

    if (cookies.crumb && options.allowCrumb !== false) {
        config.headers['X-CSRF-Token'] = cookies.crumb;
    }

    if (options.query) {
        config.url +=
            '?' +
            Qs.stringify(options.query, {
                arrayFormat: 'repeat',
                skipNulls: true,
                encode: true
            });
    }

    if (options.data) {
        config.body =
            options.type !== 'multipart/form-data' ? JSON.stringify(options.data) : options.data;
    } else {
        config.body = '{}';
    }

    if (options.headers) {
        _merge(config.headers, options.headers);
    }

    if (options.onProgress) {
        config.beforeSend = (obj) => {
            obj.upload.onprogress = options.onProgress;
        };
    }

    Xhr(config, (err, response, body) => {
        if (err) {
            return callback(err);
        }

        const responseContentType = response.headers['content-type'];

        if (response.statusCode >= 200 && response.statusCode < 300) {
            if (response.headers.hasOwnProperty('x-auth-required')) {
                let returnUrl = window.location.pathname;

                if (window.location.search.length > 0) {
                    returnUrl += window.location.search;
                }

                returnUrl = encodeURIComponent(returnUrl);

                window.location.href = `/login?returnUrl=${returnUrl}`;
            } else {
                const parsed = parse(responseContentType, body);
                callback(null, options.type !== 'content' ? parsed : body);
            }
        } else if (response.statusCode === 401 && !response.url.endsWith('/login')) {
            AuthHelper.logout();
        } else {
            const httpErr = new Error(response.rawRequest.statusText);
            const parsed = parse(responseContentType, body);
            callback(httpErr, parsed);
        }
    });
};

const parse = (contentType, body) => {
    const isXml = contentType === 'application/xml';

    if (isXml) {
        const xml = new window.DOMParser().parseFromString(body, 'text/xml');
        return xmlToJson(xml);
    }

    // treating body content as JSON by default
    return JSON.parse(body);
};

// Changes XML to JSON
const xmlToJson = function (xml) {
    // Create the return object
    let obj = {};

    if (xml.nodeType === 1) {
        // element
        // do attributes
        if (xml.attributes.length > 0) {
            obj['@attributes'] = {};
            for (let i = 0; i < xml.attributes.length; ++i) {
                const attribute = xml.attributes.item(i);
                obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
            }
        }
    } else if (xml.nodeType === 3) {
        // text
        obj = xml.nodeValue;
    }

    // do children
    if (xml.hasChildNodes()) {
        for (let i = 0; i < xml.childNodes.length; ++i) {
            const item = xml.childNodes.item(i);
            const nodeName = item.nodeName;
            if (typeof obj[nodeName] === 'undefined') {
                obj[nodeName] = xmlToJson(item);
            } else {
                if (typeof obj[nodeName].push === 'undefined') {
                    const old = obj[nodeName];
                    obj[nodeName] = [];
                    obj[nodeName].push(old);
                }

                obj[nodeName].push(xmlToJson(item));
            }
        }
    }

    return obj;
};

window.jsonFetch = jsonFetch;

module.exports = jsonFetch;
